import React from "react";
import {
	MDBView,
	MDBMask,
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBAnimation,
} from "mdbreact";
import "./AnimatedHeader.scss";

import PropTypes from "prop-types";

export default function AnimatedHeader({ contentA, contentB, image, imageb }) {
	AnimatedHeader.propTypes = {
		contentA: PropTypes.object,
		contentB: PropTypes.object,
		image: PropTypes.string,
		imageb: PropTypes.string,
	};

	const header = () => {

		// Verifica se o código está sendo executado em um ambiente de navegador
		if (typeof screen !== 'undefined') {
			// O objeto screen está disponível, é seguro usar screen.width
			let local;
			const screenWidth = screen.width;
			if (screenWidth >= 768) {
			local = image; // Defina o valor de local para image
			} else {
			local = imageb; // Defina o valor de local para imageb
			}
			return local;
		} else {
			// O código não está sendo executado no navegador, faça algo apropriado aqui (se necessário)
			console.log('Este código não está sendo executado em um navegador.');

			let local;
			local = image;
			return local; 
		}
	  
	  };

	  const text = () => {

		if (typeof screen !== 'undefined') {
			let local;
			const screenWidth = screen.width;

			// O objeto screen está disponível, é seguro usar screen.width
			if (screenWidth >= 768) {
				local = contentA; // Defina o valor de local para image
			  } else {
				local = contentB; // Defina o valor de local para imageb
			  }
			  return local; // Retorne o valor de local

		} else {
			// O código não está sendo executado no navegador, faça algo apropriado aqui (se necessário)
			console.log('Este código não está sendo executado em um navegador.');

			let local;
			local = contentA;
			return local; 
		}
	  
		
	  };

	  const estilo = () => {
		if (typeof screen !== 'undefined') {
			let local;
			const screenWidth = screen.width;

			// O objeto screen está disponível, é seguro usar screen.width
			if (screenWidth >= 768) {
				local = "largetext white-text text-center text-md-left mt-xl-5 mr-md-4 px-md-1 d-flex align-items-center"; // Defina o valor de local para image
			  } else {
				local = "mobiletext white-text text-center text-md-left mt-xl-5 mr-md-4 px-md-5 d-flex align-items-end"; // Defina o valor de local para imageb
			  }
			
			  return local; // Retorne o valor de local

		} else {
			// O código não está sendo executado no navegador, faça algo apropriado aqui (se necessário)
			console.log('Este código não está sendo executado em um navegador.');

			let local;
			local = "white-text text-center text-md-left mt-xl-5 mr-md-4 px-md-1 d-flex align-items-center";
			return local; 
		}
	  
	  };
	  

	return (

		<div className="animatedHeader-wrapper">
			<MDBView src = {header()}>
				<MDBMask className="d-flex justify-content-center align-items-center">
					<MDBContainer className="h-100">
						<MDBRow className="p-5 h-100">
							<MDBCol
								size="12"
								md="6"
								className={estilo()}
							>
								{contentA != null && contentA.type === React.Fragment ? (
									<>{text()}</>
								) : (
									<MDBAnimation className="largeimage" type="fadeInLeft" delay=".3s">
										{text()}
									</MDBAnimation>
								)}
							</MDBCol>
							<MDBCol
								size="12"
								md="6"
								xl="5"
								className="ml-md-5 mt-xl-5 align-items-md-center d-flex"
							>
								<MDBAnimation type="fadeInRight" delay=".3s">
									{}
								</MDBAnimation>
							</MDBCol>
						</MDBRow>
					</MDBContainer>
				</MDBMask>
			</MDBView>
		</div>
	);
}
