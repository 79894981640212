import { MDBBtn, MDBCol, MDBContainer, MDBRow, MDBBox } from "mdbreact";
import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";


import AnimatedHeader from "../components/Headers/AnimatedHeader/AnimatedHeader";
import SEO from "../components/SEO/Seo";
import { Link } from "gatsby";

import Layout from "../Layouts/MasterLayout/MasterLayout";
// header
import HeaderBackground from "../assets/images/licenciaturas/headerBackground.png";
import HeaderBalloon from "../assets/images/licenciaturas/headerBalloon.png";
import HeaderBalloonSmall from "../assets/images/licenciaturas/headerBalloon.png";
import HeaderBackgroundSmall from "../assets/images/licenciaturas/headerBackgroundMobile.png";

let HeaderBallonProp = (
	<img
		src={HeaderBalloon}
		alt="Experiência completa na formação docente, para você se destacar no mercado de trabalho"
		className="img-fluid"
	/>
);

let HeaderBallonPropSmall = (
	<img
		src={HeaderBalloonSmall}
		alt="Experiência completa na formação docente, para você se destacar no mercado de trabalho"
		className="img-fluid"
	/>
);

export default function Index() {
	return (
		<Layout>
			<SEO
				title="Licenciaturas"
				description="Experiência completa na formação docente para você se destacar no mercado de trabalho"
			/>
			<AnimatedHeader contentA={HeaderBallonProp} contentB={HeaderBallonPropSmall} image={HeaderBackground} imageb={HeaderBackgroundSmall} />
			<MDBContainer className="text-primary py-4">
				<MDBRow>
					<MDBCol>
						<h2 className="text-secondary">
							A EDUCAÇÃO FAZ PARTE DA HISTÓRIA DA FEEVALE.
						</h2>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol style={{color:"black"}}>
						<p>
							A Universidade Feevale conta com cursos que preparam futuros
							professores para um mundo que precisa deles, que está em constante
							mudança, repleto de novos desafios, onde a educação é primordial e
							deve fazer parte da vida de cada pessoa.
						</p>
						<p>
							Ao se formar em uma licenciatura, os profissionais estarão
							capacitados a desempenhar e conduzir a gestão do ensino, com foco
							na formação integral do indivíduo.
						</p>
						<p>
							Grande parte dos cursos de licenciatura da Feevale pode ser feita
							em Digital – EaD, sem perder a qualidade Feevale que está sempre
							presente, tendo todos os recursos necessários para que a sua
							experiência seja a melhor e encaminhe você rumo à empregabilidade
							desejada.
						</p>
					</MDBCol>
				</MDBRow>
			</MDBContainer>
			<MDBContainer className="pt-3 pb-5">
				<MDBRow>
					<MDBCol>
						<h3 className="text-secondary">
							CONHEÇA NOSSOS CURSOS DE LICENCIATURA
						</h3>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol md={6} className="text-center pt-3">
						<Link to="/cursos/artes-visuais" className="text-primary">
							<MDBBtn
								block
								className="text-white font-weight-bold"
								color="primary"
							>
								Artes Visuais
							</MDBBtn>
						</Link>
					</MDBCol>
					<MDBCol md={6} className="text-center pt-3">
						<Link to="/cursos/pedagogia">
							<MDBBtn
								block
								className="text-white font-weight-bold"
								color="primary"
							>
								Pedagogia
							</MDBBtn>
						</Link>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol md={6} className="text-center pt-3">
						<Link to="/cursos/educacao-fisica" className="text-primary">
							<MDBBtn
								block
								className="text-white font-weight-bold"
								color="primary"
							>
								Educação Física
							</MDBBtn>
						</Link>
					</MDBCol>
					<MDBCol md={6} className="text-center pt-3">
						<Link to="/cursos/historia">
							<MDBBtn
								block
								className="text-white font-weight-bold"
								color="primary"
							>
								História
							</MDBBtn>
						</Link>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol md={12} className="text-center pt-3">
						<Link to="/cursos/letras" className="text-primary">
							<MDBBtn
								block
								className="text-white font-weight-bold"
								color="primary"
							>
								Letras
							</MDBBtn>
						</Link>
					</MDBCol>
				</MDBRow>
			</MDBContainer>
			<MDBBox bgColor="primary">
				<MDBContainer className="text-white py-4">
					<MDBRow className="text-center">
						<MDBCol className="py-3">
							<h3>VANTAGENS DE FAZER LICENCIATURA DA FEEVALE</h3>
						</MDBCol>
					</MDBRow>
					<MDBRow className="py-2">
						<MDBCol>
							<h5 className="text-white">ESCOLA DE APLICAÇÃO FEEVALE</h5>
						</MDBCol>
					</MDBRow>
					<MDBRow>
						<MDBCol>
							<p>
								A Universidade conta com a
								<a
									href="https://escola.feevale.br/a-escola/proposito"
									target="_blank"
									className="text-white font-weight-bold"
									rel="noreferrer"
								>
									{" "}
									Escola de Aplicação Feevale
								</a>
								, o que possibilita a oportunidade de realizar estágio já dentro
								da própria escola e iniciar a vida docente desde cedo. Somos a
								única universidade privada da região com uma escola de
								aplicação, que tem como diferencial basear a sua educação em
								metodologias finlandesas, referência mundial em educação.
							</p>
						</MDBCol>
					</MDBRow>
					<MDBRow className="py-2">
						<MDBCol>
							<h5 className="text-white">MENTHORED TEACHER EDUCATION</h5>
						</MDBCol>
					</MDBRow>
					<MDBRow>
						<MDBCol>
							<p>
								Por “falar” em Finlândia, quem cursa licenciatura na Feevale
								pode ter a oportunidade de participar de um programa que visa
								qualificar professores dentro da metodologia finlandesa de
								educação, desenvolvendo competências essenciais para o século
								XXI, de forma inovadora, colaborativa e internacional.
							</p>
						</MDBCol>
					</MDBRow>
					<MDBRow className="py-2">
						<MDBCol>
							<h5 className="text-white">
								PARCERIAS COM UNIVERSIDADES DO MUNDO TODO
							</h5>
						</MDBCol>
					</MDBRow>
					<MDBRow>
						<MDBCol>
							<p>
								A Universidade Feevale possui convênio com universidades de mais
								de 20 países, o que facilita intercâmbios de docentes e
								pesquisadores, expandindo currículo e gerando novas
								experiências.
							</p>
						</MDBCol>
					</MDBRow>
					<MDBRow>
						<MDBCol className="text-center">
							<MDBBtn
								className="text-white font-weight-bold px-sm-5"
								color="secondary"
								href="https://www.feevale.br/institucional/bem-vindo/relacoes-internacionais/convenios"
								target="blank"	>
								Confira aqui quais são elas
							</MDBBtn>
						</MDBCol>
					</MDBRow>
				</MDBContainer>
			</MDBBox>
			<MDBBox bgColor="primary">
				<MDBContainer className="text-white py-4">
					<MDBRow>
						<MDBCol>
							<h5 className="text-white">PIBID</h5>
						</MDBCol>
					</MDBRow>
					<MDBRow>
						<MDBCol>
							<p>
								O Programa Institucional de Bolsa de Iniciação à Docência,
								organizado e financiado pela CAPES/MEC – Coordenação de
								Aperfeiçoamento Pessoal de Nível Superior do Ministério da
								Educação em parceria com a Feevale, proporciona aos estudantes
								dos cursos de licenciatura inserção no cotidiano das escolas
								públicas de educação básica.
							</p>
							<p>
								Esta é mais uma oportunidade para os futuros professores
								experenciarem a sala de aula. É uma parceria da Universidade
								Feevale com as Secretarias de Educação dos municípios da região,
								a partir da inserção dos acadêmicos como bolsistas em escolas
								públicas, promovendo a integração entre Educação Superior e
								Educação Básica.
							</p>
						</MDBCol>
					</MDBRow>
					<MDBRow>
						<MDBCol className="text-center">
							{/*<MDBBtn
								className="text-white font-weight-bold px-sm-5"
								color="secondary"
								href="https://www.feevale.br/graduacao/programas-de-ensino/pibid---iniciacao-a-docencia"
								target="blank">
								Como ingressar
							</MDBBtn>*/}
						</MDBCol>
					</MDBRow>
				</MDBContainer>
			</MDBBox>
			<MDBBox bgColor="primary">
				<MDBContainer className="text-white py-4">
					<MDBRow>
						<MDBCol>
							<h5 className="text-white">PROBITI</h5>
						</MDBCol>
					</MDBRow>
					<MDBRow>
						<MDBCol>
							<p>
								O Programa Institucional de Bolsas de Iniciação e Inovação
								(PROBITI) da FAPERGS estimula os estudantes de ensino superior
								ao interesse pela pesquisa científica, ao desenvolvimento
								tecnológico e à inovação, através da participação nas atividades
								teórico-práticas nas diversas áreas do conhecimento e
								especialidades, incentivando o pensamento científico dos
								estudantes.
							</p>
						</MDBCol>
					</MDBRow>
					<MDBRow>
						<MDBCol className="text-center">
							<MDBBtn
								className="text-white font-weight-bold px-sm-5"
								color="secondary"
								href="https://www.feevale.br/pesquisa-e-extensao/iniciacao-a-pesquisa-e-extensao/iniciacao-cientifica-probiti-fapergs"
								target="blank">
								Saiba mais
							</MDBBtn>
						</MDBCol>
					</MDBRow>
				</MDBContainer>
			</MDBBox>
			<MDBBox bgColor="primary">
				<MDBContainer className="text-white py-4">
					<MDBRow>
						<MDBCol>
							<h5 className="text-white">PET</h5>
						</MDBCol>
					</MDBRow>
					<MDBRow>
						<MDBCol>
							<p>
								O Programa de Educação Tutorial objetiva desenvolver atividades
								acadêmicas em padrões de qualidade e excelência, mediante grupos
								de aprendizagem tutorial de natureza coletiva e
								interdisciplinar, contribuir para a elevação da qualidade da
								formação acadêmica dos estudantes de graduação, estimular a
								formação de profissionais e docentes de elevada qualificação
								técnica, científica, tecnológica e acadêmica, formular novas
								estratégias de desenvolvimento e modernização do ensino superior
								no país e estimular o espírito crítico, bem como a atuação
								profissional pautada pela cidadania e pela função social da
								educação superior.
							</p>
						</MDBCol>
					</MDBRow>
					<MDBRow>
						<MDBCol className="text-center">
							<MDBBtn
								className="text-white font-weight-bold px-sm-5"
								color="secondary"
								href="https://www.feevale.br/graduacao/programas-de-ensino/programa-pet"
								target="_blank"
							>
								Saiba mais
							</MDBBtn>
						</MDBCol>
					</MDBRow>
				</MDBContainer>
			</MDBBox>
			<MDBContainer className="py-4">
				<MDBRow>
					<MDBCol>
						<h3 className="text-secondary">CONTEÚDOS QUE PODEM LHE AJUDAR</h3>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol className="pt-3 col-12 col-md-6">
						<iframe
							width="100%"
							height="315"
							src="https://www.youtube.com/embed/videoseries?list=PLdSg1_NvZd7aOY5CQgkOUIItIXls450Zb"
							title="Copia e tenta (Youtube)"
							frameborder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
							allowFullScreen={true}
						></iframe>
					</MDBCol>
					<MDBCol className="pt-3 col-12 col-md-6">
						<iframe
							width="100%"
							height="315"
							src="https://www.youtube.com/embed/videoseries?list=PLdSg1_NvZd7aKIMvqxw60wkuz5bN9YWof"
							title="Portfólio (Youtube)"
							frameborder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
							allowFullScreen={true}
						></iframe>
					</MDBCol>
				</MDBRow>
			</MDBContainer>
		</Layout>
	);
}
